import { FaPhoneVolume } from "react-icons/fa6";
import { BsGeoAlt } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { FaFacebook } from "react-icons/fa";
import { LuInstagram } from "react-icons/lu";
import { FaWhatsapp } from "react-icons/fa";
import { color1, playStore } from '../componentes/config.js';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';



const PiePagina=()=>{
  const correo = "jdpadillac@gmail.com";
  const asunto = "Información de servicios";
  const cuerpo = `Hola, necesito información adicional sobre como prestar y solicitar los servicios`;

  return(
    <div className="container mt-3">
      <div className="row">

        <div className="col-sm-4">
          <h5 style={{color:'#7ff'}}>Descargar App</h5>
          <hr />
          <p className="p-0" style={{color:color1}}> 
            <a className="nav-link" href={playStore} 
              target="_blank" 
              rel="noreferrer" 
              style={{ color:color1}}
              ><PhoneIphoneIcon /> Descargar la App
            </a>
          </p>
        </div>


        <div className="col-sm-4">
          <h5 style={{color:'#7ff'}}>Contacto</h5>
          <hr />
          <p className="p-0" style={{color:color1}}><FaPhoneVolume /> 302-8551507</p>
          <p className="p-0" style={{color:color1}}>
            <a href="https://api.whatsapp.com/send?phone=573028551507&amp;text=Hola,%20requiero%20mas%20información..." 
              target="_blank" 
              rel="noreferrer" 
              style={{color:color1,textDecoration:'none'}}>
              <FaWhatsapp /> 302-8551507
            </a>
          </p>
          <p className="p-0" style={{color:color1}}>
            <a className="nav-link" 
              href={`mailito:${correo}?subject=${asunto}&body=${cuerpo}`} 
              target="_blank">
              {' '} <CiMail/> jdpadillac@gmail.com
            </a>
          </p>
        </div>

        <div className="col-sm-4">
          <h5 style={{color:'#7ff'}}>Redes Sociales</h5>
          <hr />
          <ul 
            className='p-2' 
            style={{listStyle:'none', textDecoration:'none'}}>
            <li>
              <a 
                href='https://www.facebook.com' 
                target="_blank" 
                rel="noreferrer" 
                style={{color:color1,textDecoration:'none'}}><FaFacebook /> Facebook
              </a>
            </li>
            <li>
              <a 
                href='https://www.instagram.com/' 
                target="_blank" 
                rel="noreferrer" 
                style={{color:color1,textDecoration:'none'}}><LuInstagram /> Instagram
              </a>
            </li>
            <li>
              <a href="https://api.whatsapp.com/send?phone=573028551507&amp;text=Hola,%20requiero%20mas%20información..." 
                target="_blank" 
                rel="noreferrer" 
                style={{color:color1,textDecoration:'none'}}><FaWhatsapp /> WhatsApp
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="row" style={{ padding:20}}>
        <hr />
        <p style={{ fontSize:12}} className="text-center"><a href="https://www.linkedin.com/in/josedavidpadillacardenas/" target='_blank' style={{color:'#7ff',textDecoration:'none'}} >Copyright © 2024 Designed by JDPC Software</a></p>
      </div>
    </div>
  )
}

export default PiePagina