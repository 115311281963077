import img1 from '../assets/galeria/gallery1.jpg'
import img2 from '../assets/galeria/gallery2.jpg'
import img3 from '../assets/galeria/gallery3.jpg'
import img4 from '../assets/galeria/conductora.jpeg'
import img5 from '../assets/galeria/gallery7.jpg'
import img6 from '../assets/galeria/conductora1.jpeg'
import img7 from '../assets/galeria/conductora3.webp'

import img10 from '../assets/galeria/driver1.jpg'
import img11 from '../assets/galeria/driver2.jpg'
import img12 from '../assets/galeria/driver3.jpg'
import img13 from '../assets/galeria/driver4.jpg'
import img14 from '../assets/galeria/driver5.jpg'
import img15 from '../assets/galeria/driver6.jpg'

const Galeria=()=>{
  return(
    <div className="container">
      <div className="row">
        <h1>GALERIA DE IMAGENES DE SERVICIOS</h1>
      </div>
      <div className="row">
        <div style={{ alignContent:'center', alignItems:'center'}} className='p-3'>
        <img src={img1} style={{'height':200}} className="img-fluid" alt="..."/>
        <img src={img2} style={{'height':200}} className="img-fluid" alt="..."/>
        <img src={img3} style={{'height':200}} className="img-fluid" alt="..."/>
        <img src={img4} style={{'height':200}} className="img-fluid" alt="..."/>
        <img src={img5} style={{'height':200}} className="img-fluid" alt="..."/>
        <img src={img6} style={{'height':200}} className="img-fluid" alt="..."/>
        <img src={img7} style={{'height':200}} className="img-fluid" alt="..."/>

        <img src={img10} style={{'height':200}} className="img-fluid" alt="..."/>
        <img src={img11} style={{'height':200}} className="img-fluid" alt="..."/>
        <img src={img12} style={{'height':200}} className="img-fluid" alt="..."/>
        <img src={img13} style={{'height':200}} className="img-fluid" alt="..."/>
        <img src={img14} style={{'height':200}} className="img-fluid" alt="..."/>
        <img src={img15} style={{'height':200}} className="img-fluid" alt="..."/>

      </div>
      </div>
    </div>
    
  )
}

export default Galeria