
import plomero from '../assets/carrusel/plomero.jpg';
import albanil from '../assets/carrusel/albanil.jpg';
import desarrollador from '../assets/carrusel/desarrollador.jpg';
import disenio from '../assets/carrusel/disenio.jpg';
import jardinero from '../assets/carrusel/jardinero.jpg';
import pintor from '../assets/carrusel/pintor.jpg';


const Carrusel=()=>{
  return(
    <div id="carouselExampleCaptions" class="carousel slide">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src={plomero} class="d-block w-100" alt="..."/>
          <div class="carousel-caption d-none d-md-block">
            <h4>PLOMEROS</h4>
            <h5>¿Tiene un problema de plomeria?</h5>
            <p>¡Nosotros le damos solución!</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src={albanil} class="d-block w-100" alt="..."/>
          <div class="carousel-caption d-none d-md-block">
            <h4>ALBAÑILES</h4>
            <h5>Contrate personal calificado para hacer  o reparar su casa!</h5>
          </div>
        </div>
        <div class="carousel-item">
          <img src={desarrollador} class="d-block w-100" alt="..."/>
          <div class="carousel-caption d-none d-md-block">
            <h4>DESARROLLADORES DE SOFTWARE</h4>
            <h5>Personal experto y calificado para desarrollo web, movil y de escritorio</h5>
          </div>
        </div>
        <div class="carousel-item">
          <img src={disenio} class="d-block w-100" alt="..."/>
          <div class="carousel-caption d-none d-md-block">
            <h4>DISEÑO GRAFICO</h4>
            <h5>Excelentes profesionales que dan solución inmediata a su necesidad!</h5>
          </div>
        </div>
        <div class="carousel-item">
          <img src={jardinero} class="d-block w-100" alt="..."/>
          <div class="carousel-caption d-none d-md-block">
            <h5>JARDINEROS</h5>
            <p>Mantenga su jardin limpio y bonito.</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src={pintor} class="d-block w-100" alt="..."/>
          <div class="carousel-caption d-none d-md-block">
            <h5>PINTORES</h5>
            <p>Excelente pintores con profesionalismo!.</p>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default Carrusel