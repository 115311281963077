import React, { useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { useState } from 'react'
import { useMapEvents } from 'react-leaflet'

const MapView = (props) =>{
  const myApiKey = "AIzaSyAIiy0VJBq-col1gOu2I6XSiQFtYJrWNK4";
  const [latitud, setLatitud] = useState(0)
  const [longitud, setLongitud] = useState(0)
  const [position, setPosition] = useState(null)
  let tamanio = props.Tamanio


  const enviarCoordenadas = (latlng)=>{
    // props.Coordenadas(latitud, longitud)
    return new Promise((resolve, reject) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${latlng.lat},${latlng.lng}&key=${myApiKey}`
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "OK") {
          props.Ubicacion({
            'Municipio' : responseJson?.results?.[0].address_components?.[0].long_name,
            'Direccion' : responseJson?.results?.[0].formatted_address,
            'Lat' :   responseJson?.results?.[0].geometry.location.lat,
            'Lon' :  responseJson?.results?.[0].geometry.location.lng
          })

          resolve(responseJson?.results?.[0]);

        } else if (responseJson.status === "ZERO_RESULTS") {
          resolve("NO Encontró la dirección");
          //reject(responseJson.status);
        }
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  const LocationMarker=()=> {
     const map = useMapEvents({
      click() {
        map.locate()
      },
      locationfound(e) {
        setPosition(e.latlng)
        setLatitud(e.latlng.lat)
        setLongitud(e.latlng.lng)
        enviarCoordenadas(e.latlng);
        map.flyTo(e.latlng, map.getZoom())
      }
      }) 

    return position === null ? null : (
      <Marker position={position}>
        <Popup>Usted esta aqui! <br></br> Latitud: {position.lat} Longitud: {position.lng}</Popup>
      </Marker>
    )
  }


  return (
    <div>
      <MapContainer 
        center={{lat:latitud, lon:longitud}} 
        zoom={13}
        style={{ height: tamanio+"vh" }}
      >
        <TileLayer  
          attribution='&copy; 
          <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
        />
        <LocationMarker />
      </MapContainer>
    </div>
  )
}

export default MapView

